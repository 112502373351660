<template>
  <a-card :bordered="false">
    <div class="card-title">合同发放详情</div>
    <div class="table-operator">
      <a-button  v-if="!isEmpty(contract)" v-action:ffht type="primary" icon="plus" @click="handleAdd">发放合同</a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <template v-if="!isEmpty(contract)">
      <s-table
          ref="table"
          rowKey="cl_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
      >
        <div slot="personnel_type" slot-scope="text, item">
          <a-tag color="blue" v-if="item.personnel_type==0">司机</a-tag>
          <a-tag color="orange" v-if="item.personnel_type==1">押运员</a-tag>
        </div>
        <div slot="status" slot-scope="text, item">
          <a-tag color="orange" v-if="text===0">待签名</a-tag>
          <a-tag color="blue" v-if="text===1">已签名</a-tag>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:ylht2 @click="handlePreview(item)">预览合同</a>
          <a v-action:scht2 @click="handleDelete(item)">删除</a>
        </div>
      </s-table>
      <SaveForm :contract_id="contract.contract_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
    </template>
    <template v-else>
      <div style="height: 500px;display: flex;align-items: center;justify-content: center">
        <a-empty>
          <span slot="description"> 请在左侧合同列表中点击一项合同 </span>
        </a-empty>
      </div>
    </template>

  </a-card>
</template>

<script>
import * as Api from '@/api/enterprise/contractLog'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm'
import SearchForm from './modules/SearchForm'
import {isEmpty} from "@/utils/util";

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          title: '员工名称',
          dataIndex: 'personnel.personnel_name',
        },
        // {
        //   title: '职位',
        //   dataIndex: 'personnel.personnel_type',
        //   scopedSlots: {customRender: 'personnel_type'}
        // },
        {
          title: '合同状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '签名时间',
          dataIndex: 'sign_time'
        },
        {
          title: '发放时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },

      contract: {}
    }
  },
  created() {

  },
  methods: {
    isEmpty,
    setData(item) {
      this.contract = item
      this.queryParam.contract_id = item.contract_id
      this.handleRefresh(true)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },
    handlePreview(item) {
      const url = item.save_contract_url ? item.save_contract_url : item.contract_file.external_url
      window.open('https://www.pfile.com.cn/api/profile/onlinePreview?url=' + url)
    },
    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({cl_id: item['cl_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$nextTick(() => {
        this.$refs.table.refresh(bool)
      })
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
